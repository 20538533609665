<template>
  <div class="hello">
    <h1>{{ title }}</h1>
    <div class="tabs">
      <input type="radio" id="tab1" name="tab-control" checked>
      <input type="radio" id="tab2" name="tab-control">
      <input type="radio" id="tab3" name="tab-control">
      <ul>
        <li title="Features"><label for="tab1" role="button"><svg viewBox="0 0 24 24">
              <path
                d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
            </svg><br><span>My Pols</span></label></li>
        <li title="Delivery Contents"><label for="tab2" role="button" @click="showChart"><svg viewBox="0 0 24 24">
              <path
                d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
            </svg><br><span>Summary</span></label></li>
        <li title="Shipping"><label for="tab3" role="button" @click="getRankList"><svg viewBox="0 0 24 24">
              <path
                d="M3,4A2,2 0 0,0 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8H17V4M10,6L14,10L10,14V11H4V9H10M17,9.5H19.5L21.47,12H17M6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z" />
            </svg><br><span>Rank</span></label></li>
      </ul>

      <div class="slider">
        <div class="indicator"></div>
      </div>
      <div class="content">
        <section>
          <h2>My Pols</h2>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="input your wallet address. eg:0x1234...."
              aria-label="input your wallet address. eg:0x1234...." aria-describedby="my-pols" id="address"
              v-model="walletAddress">
            <button class="btn btn-outline-secondary" type="button" @click="getBalance">Click me!</button>
          </div>
          <ul class="balance">
            <li v-for="item in ticklist.data" v-bind:key="item.tick">{{ item.tick }} -- {{ item.amount }}</li>
          </ul>
        </section>
        <section>
          <h2>Delivery Contents</h2>
          <div ref="chart" style="width:600px;height:400px;"></div>
        </section>
        <section>
          <h2>Rank</h2>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>address</th>
                <th>amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in ranklist_.items || []" v-bind:key="index">
                <td>{{ (currentPage - 1) * pageSize + index + 1 }}</td>
                <td>{{ item.address }}</td>
                <td>{{ formatNumber(item.amount) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item" @click="changePage(-1)" :disabled="currentPage === 1">
                        <span class="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </span>
                      </li>
                      <li class="page-item" v-for="page in totalPages" :key="page"
                        :class="{ 'active': isCurrentPage(page) }" @click="goToPage(page)">
                        <span class="page-link">{{ page }}</span>
                      </li>
                      <li class="page-item" @click="changePage(1)" :disabled="currentPage === totalPages">
                        <span class="page-link" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </span>
                      </li>
                    </ul>
                  </nav>
                </td>
              </tr>
            </tfoot>
          </table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Web3 from 'web3';
import * as echarts from 'echarts'
export default {
  name: 'HelloWorld',
  props: {
    title: String
  },
  data() {
    return {
      currentPage: 1,
      walletAddress: '',
      ticklist: [],
      ranklist: [],
      ranklist_: [],
      pageSize: 30,
      chartData: {
        backgroundColor: '#2c343c',
        title: {
          text: 'Customized Pie',
          left: 'center',
          top: 20,
          textStyle: {
            color: '#ccc'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1]
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: [
              { value: 335, name: 'Direct' },
              { value: 310, name: 'Email' },
              { value: 274, name: 'Union Ads' },
              { value: 235, name: 'Video Ads' },
              { value: 400, name: 'Search Engine' }
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: 'radius',
            label: {
              color: 'rgba(255, 255, 255, 0.3)'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            itemStyle: {
              color: '#c23531',
              shadowBlur: 200,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            animationType: 'scale',
            animationEasing: 'elasticOut'
          }
        ]
      }
    }
  },
  mounted() {
    this.initMetaMask();
    this.initChart()
  },
  computed: {
    totalPages() {
      return this.ranklist.length;
    },
    pageList() {
      const mid = Math.ceil(this.totalPages / 2);
      if (this.page <= 5 || this.page >= this.totalPages - 4) {
        return [...Array(this.totalPages).keys()].map(x => x + 1)
      }

      // middle page
      return [1, mid - 1, mid, mid + 1, this.totalPages()]
    },
    currentPageData() {
      if (!this.ranklist) return [];
      console.log(this.ranklist);
      return this.ranklist[this.currentPage - 1];
    }
  },
  methods: {
    isCurrentPage(page) {
      return page === this.currentPage;
    },
    getBalance() {
      axios.get("/data/balance.json")
        .then(resp => {
          if (resp.data.code == 200) {
            this.ticklist = resp.data;
          }
        })
        .catch(error => {
          console.error('Error loading test data:', error);
        });
    },
    getRankList() {
      axios.get("/data/rank.json")
        .then(resp => {
          this.ranklist = resp.data
          this.ranklist_ = this.ranklist[this.currentPage - 1];
        })
        .catch(error => {
          console.error('Error loading test data:', error);
        });
    },
    changePage(offset) {
      if (this.currentPage + offset < 1 || this.currentPage + offset > this.totalPages) {
        return false;
      }
      this.currentPage += offset;
      this.ranklist_ = this.ranklist[this.currentPage - 1];
    },
    goToPage(page) {
      // 跳转到指定页码
      if (page < 1 || page > this.totalPages || page == this.currentPage) {
        return false;
      }
      this.currentPage = page;
      this.ranklist_ = this.ranklist[page - 1];
    },
    formatNumber(value) {
      // 使用内置的 currency 过滤器进行格式化
      return value.toLocaleString('en-US');
    },
    initChart() {
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption(this.chartData)
    },
    async initMetaMask() {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          // 请求用户授权连接到 MetaMask
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          // 获取用户的钱包地址
          const accounts = await web3.eth.getAccounts();
          this.walletAddress = accounts[0];

          // console.log('address:', this.walletAddress);
        } catch (error) {
          console.error('init MetaMask error:', error);
        }
      } else {
        console.error('MetaMask uninstalled');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700|Raleway:300,400';

html {
  width: 100%;
  height: 100%;
}

body {
  background: #efefef;
  color: #333;
  font-family: "Raleway";
  height: 100%;
}

body h1 {
  text-align: center;
  color: #428BFF;
  font-weight: 300;
  padding: 40px 0 20px 0;
  margin: 0;
}

.tabs {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  background: white;
  padding: 50px;
  padding-bottom: 80px;
  width: 70%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  min-width: 240px;
}

.tabs input[name=tab-control] {
  display: none;
}

.tabs .content section h2,
.tabs ul li label {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  color: #428BFF;
}

.tabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.tabs ul li {
  box-sizing: border-box;
  flex: 1;
  width: 25%;
  padding: 0 10px;
  text-align: center;
}

.tabs ul li label {
  transition: all 0.3s ease-in-out;
  color: #929daf;
  padding: 5px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabs ul li label br {
  display: none;
}

.tabs ul li label svg {
  fill: #929daf;
  height: 1.2em;
  vertical-align: bottom;
  margin-right: 0.2em;
  transition: all 0.2s ease-in-out;
}

.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
  outline: 0;
  color: #bec5cf;
}

.tabs ul li label:hover svg,
.tabs ul li label:focus svg,
.tabs ul li label:active svg {
  fill: #bec5cf;
}

.tabs .slider {
  position: relative;
  width: 33%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.tabs .slider .indicator {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: #428BFF;
  border-radius: 1px;
}

.tabs .content {
  margin-top: 30px;
}

.tabs .content section {
  display: none;
  -webkit-animation-name: content;
  animation-name: content;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1.4;
}

.tabs .content section h2 {
  color: #428BFF;
  display: none;
}

.tabs .content section h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #428BFF;
  margin-top: 5px;
  left: 1px;
}

.tabs .content .balance {
  padding-left: 15px;
  list-style: decimal;
  display: flex;
  flex-direction: column;
}

.tabs section ul.balance li {
  flex: auto;
  width: 100%;
  padding: 10px;
  text-align: left;
}

.tabs input[name=tab-control]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
  cursor: default;
  color: #428BFF;
}

.tabs input[name=tab-control]:nth-of-type(1):checked~ul>li:nth-child(1)>label svg {
  fill: #428BFF;
}

@media (max-width: 600px) {
  .tabs input[name=tab-control]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name=tab-control]:nth-of-type(1):checked~.slider {
  transform: translateX(0%);
}

.tabs input[name=tab-control]:nth-of-type(1):checked~.content>section:nth-child(1) {
  display: block;
}

.tabs input[name=tab-control]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
  cursor: default;
  color: #428BFF;
}

.tabs input[name=tab-control]:nth-of-type(2):checked~ul>li:nth-child(2)>label svg {
  fill: #428BFF;
}

@media (max-width: 600px) {
  .tabs input[name=tab-control]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name=tab-control]:nth-of-type(2):checked~.slider {
  transform: translateX(100%);
}

.tabs input[name=tab-control]:nth-of-type(2):checked~.content>section:nth-child(2) {
  display: block;
}

.tabs input[name=tab-control]:nth-of-type(3):checked~ul>li:nth-child(3)>label {
  cursor: default;
  color: #428BFF;
}

.tabs input[name=tab-control]:nth-of-type(3):checked~ul>li:nth-child(3)>label svg {
  fill: #428BFF;
}

@media (max-width: 600px) {
  .tabs input[name=tab-control]:nth-of-type(3):checked~ul>li:nth-child(3)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name=tab-control]:nth-of-type(3):checked~.slider {
  transform: translateX(200%);
}

.tabs input[name=tab-control]:nth-of-type(3):checked~.content>section:nth-child(3) {
  display: block;
}

.tabs input[name=tab-control]:nth-of-type(4):checked~ul>li:nth-child(4)>label {
  cursor: default;
  color: #428BFF;
}

.tabs input[name=tab-control]:nth-of-type(4):checked~ul>li:nth-child(4)>label svg {
  fill: #428BFF;
}

@media (max-width: 600px) {
  .tabs input[name=tab-control]:nth-of-type(4):checked~ul>li:nth-child(4)>label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name=tab-control]:nth-of-type(4):checked~.slider {
  transform: translateX(300%);
}

.tabs input[name=tab-control]:nth-of-type(4):checked~.content>section:nth-child(4) {
  display: block;
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
  }

  .tabs ul li label br {
    display: initial;
  }

  .tabs ul li label svg {
    height: 1.5em;
  }
}

@media (max-width: 600px) {
  .tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }

  .tabs ul li label span {
    display: none;
  }

  .tabs .slider {
    display: none;
  }

  .tabs .content {
    margin-top: 20px;
  }

  .tabs .content section h2 {
    display: block;
  }
}
</style>
