import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'

import ECharts from 'vue-echarts'
// 引入 ECharts 主模块
import 'echarts';


const app = createApp(App);
// 注册 ECharts 组件
app.component('v-chart', ECharts);
// 挂载应用
app.mount('#app');
